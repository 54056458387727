import axios, { AxiosInstance, AxiosResponse } from 'axios';

import { IMenu } from '@/components/ReportsMenu/ReportsMenu';
import { objToQueryString } from '@/router/router';
import { HTTP } from '@/store/enums';
import {
  IContact,
  ILabelItem,
  IListItem,
  IMls,
  IPassword,
  IProfile,
  IReportData,
  ITimeFrameFilters,
  IUserSettings,
  Login,
  TBrandInfo,
  TFirmInfo,
  TOfficeInfo,
  TReportFields,
  TReportItem,
  TSearchTransactionList,
  TShortInfo,
  TTotals,
} from '@/store/types';

import User from './User';
import UserSearch from './UserSearch';

export default class ApiClient {
  private client: AxiosInstance | null = null;

  private userApi?: User;

  private userSearchApi?: UserSearch;

  private token?: string;

  private refreshToken: string | null = null;

  private onMissingTokenCallback?: () => void;

  private refreshingToken = false;

  private baseUrl: string | null = null;

  constructor(baseURL: string | null) {
    this.baseUrl = baseURL;
  }

  public setRefreshToken(refreshToken: string | null) {
    this.refreshToken = refreshToken;
  }

  public setBaseUrl(baseUrl: string | null) {
    this.baseUrl = baseUrl;
    this.client = null;
  }

  public async refresh() {
    this.refreshingToken = true;

    try {
      const response = await this.getClient().post<Login>(
        '/api/token/refresh',
        {
          refresh_token: this.refreshToken,
        }
      );

      if (response.status === HTTP.OK) {
        this.refreshToken = response.data.refresh_token;
        this.token = response.data.token;

        return response.data;
      }

      throw response.data;
    } finally {
      this.refreshingToken = false;
    }
  }

  public onMissingToken(fn: () => void) {
    this.onMissingTokenCallback = fn;
  }

  public async login(email: string, password: string): Promise<Login> {
    const response = await this.getClient().post<Login>('/api/login', {
      username: email,
      password,
    });

    if (response.status === HTTP.OK) {
      this.refreshToken = response.data.refresh_token;
      this.token = response.data.token;

      return response.data;
    }

    throw response.data;
  }

  public async externalLogin(
    user: string,
    expires: string,
    hash: string
  ): Promise<Login> {
    const response = await this.getClient().post<Login>(
      '/api/external-login',
      {
        user,
        expires,
        hash,
      },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );

    if (response.status === HTTP.OK) {
      this.refreshToken = response.data.refresh_token;
      this.token = response.data.token;

      return response.data;
    }

    throw response.data;
  }

  public user(): User {
    if (!this.userApi) {
      this.userApi = new User(this.getClient.bind(this));
    }

    return this.userApi;
  }

  public userSearch(): UserSearch {
    if (!this.userSearchApi) {
      this.userSearchApi = new UserSearch(this.getClient.bind(this));
    }

    return this.userSearchApi;
  }

  public async me(): Promise<IProfile> {
    const response = await this.getClient().get('/api/me');

    if (response.status === HTTP.OK) {
      return response.data;
    }

    throw response.data;
  }

  public async availableReports(mlsId: number): Promise<IMenu> {
    const response = await this.getClient().get(
      `/api/available-reports/mls/${mlsId}`
    );

    if (response.status === HTTP.OK) {
      return response.data;
    }

    throw response.data;
  }

  public async setContact(contact: IContact): Promise<IProfile> {
    const response = await this.getClient().put('/api/me/contact', {
      ...contact,
    });

    if (response.status === HTTP.OK) {
      return response.data;
    }

    throw response.data;
  }

  public async setPassword(password: IPassword): Promise<IPassword> {
    const response = await this.getClient().put('/api/me/password', {
      ...password,
    });

    if (response.status === HTTP.OK) {
      return response.data;
    }

    throw response.data;
  }

  public async getDefaults(): Promise<IUserSettings> {
    const response = await this.getClient().get('/api/me/defaults');

    if (response.status === HTTP.OK) {
      return response.data;
    }

    throw response.data;
  }

  public async setDefaults(settings: IUserSettings): Promise<IUserSettings> {
    const response = await this.getClient().put('/api/me/defaults', {
      ...settings,
    });

    if (response.status === HTTP.OK) {
      return response.data;
    }

    throw response.data;
  }

  public async mlsList(): Promise<Array<IListItem>> {
    const response = await this.getClient().get('/api/mls');

    if (response.status === HTTP.OK) {
      return response.data;
    }

    throw response.data;
  }

  public async mlsInfo(mlsId: number): Promise<IMls> {
    const response = await this.getClient().get(`/api/mls/${mlsId}`);

    if (response.status === HTTP.OK) {
      return response.data;
    }

    throw response.data;
  }

  public async loadFilters(
    mlsId: number,
    filter: string
  ): Promise<Array<IListItem | string> | ITimeFrameFilters> {
    const response = await this.getClient().get(
      `/api/mls/${mlsId}/filters/${filter}`
    );

    if (response.status === HTTP.OK) {
      return response.data;
    }

    throw response.data;
  }

  public async loadTimeFrameFilters(mlsId: number): Promise<ITimeFrameFilters> {
    const response = await this.getClient().get(
      `/api/mls/${mlsId}/filters/time-frame`
    );

    if (response.status === HTTP.OK) {
      return response.data;
    }

    throw response.data;
  }

  public async mlsLabels(mlsId: number): Promise<Array<ILabelItem>> {
    const response = await this.getClient().get(`/api/mls/${mlsId}/labels`);

    if (response.status === HTTP.OK) {
      return response.data;
    }

    throw response.data;
  }

  public async getReport(
    mlsId: number,
    reportName: string,
    filter: string,
    signal: AbortSignal
  ): Promise<Omit<IReportData, 'totals'>> {
    const response = await this.getClient().get(
      `/api/mls/${mlsId}/reports/${reportName}${filter}`,
      { signal: signal }
    );

    if (response.status === HTTP.OK) {
      return response.data;
    }

    throw response.data;
  }

  public async getGraphsReport(
    mlsId: number,
    reportName: string,
    filter: string
  ): Promise<Omit<IReportData, 'totals'>> {
    const response = await this.getClient().get(
      `/api/mls/${mlsId}/reports/${reportName}${filter}`
    );

    if (response.status === HTTP.OK) {
      return response.data;
    }

    throw response.data;
  }

  public async getReportTotals(
    mlsId: number,
    reportName: string,
    filter: string,
    signal: AbortSignal
  ): Promise<TTotals> {
    const response = await this.getClient().get(
      `/api/mls/${mlsId}/reports/${reportName}/totals${filter}`,
      { signal: signal }
    );

    if (response.status === HTTP.OK) {
      return response.data;
    }

    throw response.data;
  }

  public async getReportSecondTotals(
    mlsId: number,
    reportName: string,
    filter: string
  ): Promise<TTotals> {
    const response = await this.getClient().get(
      `/api/mls/${mlsId}/reports/${reportName}/second-totals${filter}`
    );

    if (response.status === HTTP.OK) {
      return response.data;
    }

    throw response.data;
  }

  public async getReportGraphsTotals(
    mlsId: number,
    reportName: string,
    filter: string
  ): Promise<TTotals> {
    const response = await this.getClient().get(
      `/api/mls/${mlsId}/reports/${reportName}/graphs/totals${filter}`
    );

    if (response.status === HTTP.OK) {
      return response.data;
    }

    throw response.data;
  }

  public async getReportTotalItems(
    mlsId: number,
    reportName: string,
    filter: string,
    signal: AbortSignal
  ): Promise<TTotals> {
    const response = await this.getClient().get(
      `/api/mls/${mlsId}/reports/${reportName}/total-items${filter}`,
      { signal: signal }
    );

    if (response.status === HTTP.OK) {
      return response.data;
    }

    throw response.data;
  }

  public async searchAgent(
    mlsId: number,
    reportName: string,
    filter: string
  ): Promise<Array<TReportItem>> {
    const response = await this.getClient().get(
      `/api/mls/${mlsId}/reports/${reportName}/search?${filter}`
    );

    if (response.status === HTTP.OK) {
      return response.data.agentResults;
    }

    throw response.data;
  }

  public async searchOfficeName(
    mlsId: number,
    reportName: string,
    filter: string
  ): Promise<Array<TOfficeInfo>> {
    const response = await this.getClient().get(
      `/api/mls/${mlsId}/reports/${reportName}/search-office?${filter}`
    );

    if (response.status === HTTP.OK) {
      return response.data.officeResults;
    }

    throw response.data;
  }

  public async searchOfficeMap(
    mlsId: number,
    reportName: string,
    filter: string
  ): Promise<Array<TOfficeInfo>> {
    const response = await this.getClient().get(
      `/api/mls/${mlsId}/reports/${reportName}/map-offices${filter}`
    );

    if (response.status === HTTP.OK) {
      return response.data.mapOffices || response.data.items;
    }

    throw response.data;
  }

  public async searchTransactionsMap(
    mlsId: number,
    reportName: string,
    filter: string
  ): Promise<Array<any>> {
    const response = await this.getClient().get(
      `/api/mls/${mlsId}/reports/${reportName}/map-transactions${filter}`
    );

    if (response.status === HTTP.OK) {
      return response.data.mapTransaction || response.data.items;
    }

    throw response.data;
  }

  public async searchTransactionsCountMap(
    mlsId: number,
    reportName: string,
    filter: string
  ): Promise<Array<any>> {
    const response = await this.getClient().get(
      `/api/mls/${mlsId}/reports/${reportName}/total-map-transactions${filter}`
    );

    if (response.status === HTTP.OK) {
      return response.data;
    }

    throw response.data;
  }

  public async searchTransactionsMapCluster(
    mlsId: number,
    reportName: string,
    filter: string
  ): Promise<Array<TSearchTransactionList>> {
    const response = await this.getClient().get(
      `/api/mls/${mlsId}/reports/${reportName}/cluster-map-transactions${filter}`
    );

    if (response.status === HTTP.OK) {
      return response.data.mapClusterTransaction || response.data.items;
    }

    throw response.data;
  }

  public async searchFirmName(
    mlsId: number,
    reportName: string,
    filter: string
  ): Promise<Array<TFirmInfo>> {
    const response = await this.getClient().get(
      `/api/mls/${mlsId}/reports/${reportName}/search-firm?${filter}`
    );

    if (response.status === HTTP.OK) {
      return response.data.firmResults;
    }

    throw response.data;
  }

  public async searchBrand(
    mlsId: number,
    reportName: string,
    filter: string
  ): Promise<Array<TBrandInfo>> {
    const response = await this.getClient().get(
      `/api/mls/${mlsId}/reports/${reportName}/search-brand?${filter}`
    );

    if (response.status === HTTP.OK) {
      return response.data.brandResults;
    }

    throw response.data;
  }

  public async getDate(
    mlsId: number,
    reportName: string,
    filter: string
  ): Promise<Array<any>> {
    const response = await this.getClient().get(
      `/api/mls/${mlsId}/reports/${reportName}/dates?${filter}`
    );

    if (response.status === HTTP.OK) {
      return response.data;
    }

    throw response.data;
  }

  public async exportReport(
    mlsId: number,
    reportName: string,
    filter: string,
    type: string
  ): Promise<AxiosResponse<Blob>> {
    const response = await this.getClient().get(
      `/api/mls/${mlsId}/export/${reportName}/${type}?${filter}`,
      {
        responseType: 'blob',
      }
    );

    if (response.status === HTTP.OK) {
      return response;
    }

    throw response.data;
  }

  public async getFieldsConfig(
    reportName: string,
    reportType: string,
    mlsDatabase: string | null,
    signal: AbortSignal
  ): Promise<Array<TReportFields>> {
    const response = await this.getClient().get(
      `/api/mls/${mlsDatabase}/${reportName}-report/${reportType}/fields-config`,
      { signal: signal }
    );

    if (response.status === HTTP.OK) {
      return response.data;
    }

    throw response.data;
  }

  public async getOfficePerformanceFieldsConfig(
    reportName: string,
    reportType: string,
    mlsDatabase: string | null,
    perPage: number,
    officeIds: never[],
    officeIdsInclude: string,
    signal: AbortSignal
  ): Promise<Array<TReportFields>> {
    const response = await this.getClient().get(
      `/api/mls/${mlsDatabase}/${reportName}-report/${reportType}/fields-config?${objToQueryString(
        {
          perPage: perPage,
          officeIds: officeIds,
          officeIdsInclude: officeIdsInclude,
        }
      )}`,
      { signal: signal }
    );

    if (response.status === HTTP.OK) {
      return response.data;
    }

    throw response.data;
  }

  public async getFirmPerformanceFieldsConfig(
    reportName: string,
    reportType: string,
    mlsDatabase: string | null,
    perPage: number,
    firmIds: never[],
    firmInclude: string,
    signal: AbortSignal
  ): Promise<Array<TReportFields>> {
    const response = await this.getClient().get(
      `/api/mls/${mlsDatabase}/${reportName}-report/${reportType}/fields-config?${objToQueryString(
        { perPage: perPage, firmIds: firmIds, firmInclude: firmInclude }
      )}`,
      { signal: signal }
    );

    if (response.status === HTTP.OK) {
      return response.data;
    }

    throw response.data;
  }

  public async getBrandPerformanceFieldsConfig(
    reportName: string,
    reportType: string,
    mlsDatabase: string | null,
    perPage: number,
    brandIds: never[],
    brandInclude: string,
    signal: AbortSignal
  ): Promise<Array<TReportFields>> {
    const response = await this.getClient().get(
      `/api/mls/${mlsDatabase}/${reportName}-report/${reportType}/fields-config?${objToQueryString(
        { perPage: perPage, brandIds: brandIds, brandInclude: brandInclude }
      )}`,
      { signal: signal }
    );

    if (response.status === HTTP.OK) {
      return response.data;
    }

    throw response.data;
  }

  public async getUserAvailableAppliedSettings(
    mlsDatabase: number | null,
    signal: AbortSignal
  ): Promise<Array<TReportFields>> {
    const response = await this.getClient().get(
      `/api/available-applied-settings/mls/${mlsDatabase}`,
      { signal: signal }
    );

    if (response.status === HTTP.OK) {
      return response.data;
    }

    throw response.data;
  }

  public async getYearBookFieldsConfig(
    reportName: string,
    reportType: string,
    mlsDatabase: string | null,
    startDate: string | null,
    signal: AbortSignal
  ): Promise<Array<TReportFields>> {
    const response = await this.getClient().get(
      `/api/mls/${mlsDatabase}/${reportName}-report/${reportType}/fields-config?startDate=${startDate}`,
      { signal: signal }
    );

    if (response.status === HTTP.OK) {
      return response.data;
    }

    throw response.data;
  }

  public async getRegisteredAgentCountFieldsConfig(
    reportName: string,
    reportType: string,
    mlsDatabase: string | null,
    endDate: string | null,
    signal: AbortSignal
  ): Promise<Array<TReportFields>> {
    const response = await this.getClient().get(
      `/api/mls/${mlsDatabase}/${reportName}-report/${reportType}/fields-config?endDate=${endDate}`,
      { signal: signal }
    );

    if (response.status === HTTP.OK) {
      return response.data;
    }

    throw response.data;
  }

  public async getGraphsFieldsConfig(
    reportName: string,
    reportType: string,
    mlsDatabase: string | null,
    signal: AbortSignal
  ): Promise<Array<TReportFields>> {
    const response = await this.getClient().get(
      `/api/mls/${mlsDatabase}/${reportName}-report/${reportType}/fields-config`,
      { signal: signal }
    );

    if (response.status === HTTP.OK) {
      return response.data;
    }

    throw response.data;
  }

  public async getGraphsMarketTrendsFieldsConfig(
    reportName: string,
    data: string,
    timePeriod: string,
    mlsDatabase: string | null,
    signal: AbortSignal
  ): Promise<Array<TReportFields>> {
    const response = await this.getClient().get(
      `/api/mls/${mlsDatabase}/${reportName}-report/${data}/${timePeriod}/fields-config`,
      { signal: signal }
    );

    if (response.status === HTTP.OK) {
      return response.data;
    }

    throw response.data;
  }

  public async getDistributionFieldsConfig(
    reportName: string,
    data: string,
    reportType: string,
    mlsDatabase: string | null,
    signal: AbortSignal
  ): Promise<Array<TReportFields>> {
    const response = await this.getClient().get(
      `/api/mls/${mlsDatabase}/${reportName}-report/${reportType}/${data}/fields-config`,
      { signal: signal }
    );

    if (response.status === HTTP.OK) {
      return response.data;
    }

    throw response.data;
  }

  public async getMarketDistributionFieldsConfig(
    reportName: string,
    data: string,
    mlsDatabase: string | null,
    signal: AbortSignal
  ): Promise<Array<TReportFields>> {
    const response = await this.getClient().get(
      `/api/mls/${mlsDatabase}/${reportName}-report/${data}/fields-config`,
      { signal: signal }
    );

    if (response.status === HTTP.OK) {
      return response.data;
    }

    throw response.data;
  }

  public async getGraphsMarketComparativeFieldsConfig(
    reportName: string,
    timePeriod: string,
    mlsDatabase: string | null,
    signal: AbortSignal
  ): Promise<Array<TReportFields>> {
    const response = await this.getClient().get(
      `/api/mls/${mlsDatabase}/${reportName}-report/${timePeriod}/fields-config`,
      { signal: signal }
    );

    if (response.status === HTTP.OK) {
      return response.data;
    }

    throw response.data;
  }

  public async getMainEntitiesShortInfo(
    mlsId: number | null,
    filter: string,
    signal: AbortSignal
  ): Promise<Array<TShortInfo>> {
    const response = await this.getClient().get(
      `/api/mls/${mlsId}/main-entities-short-info${filter}`,
      { signal: signal }
    );

    if (response.status === HTTP.OK) {
      return response.data;
    }

    throw response.data;
  }

  private getClient(): AxiosInstance {
    if (this.client !== null) {
      return this.client;
    }

    if (this.baseUrl === null) {
      throw new Error('baseUrl should be set');
    }

    this.client = axios.create({ baseURL: this.baseUrl });
    this.client.interceptors.request.use((config) => {
      config.withCredentials = true;
      if (this.token && config.headers) {
        config.headers.Authorization = `Bearer ${this.token}`;
      }
      return config;
    });
    this.client.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        if (this.refreshingToken) {
          return Promise.reject(error);
        }

        const originalConfig = error.config;

        if (error.response) {
          // eslint-disable-next-line
          if (error.response.status === HTTP.UNAUTHORIZED && !originalConfig._retry) {
            // eslint-disable-next-line
            originalConfig._retry = true;

            try {
              await this.refresh();
            } catch (e) {
              if (this.onMissingTokenCallback) {
                this.onMissingTokenCallback();
                this.token = '';
              }
            }

            return this.getClient()(originalConfig);
          }
        }

        return Promise.reject(error);
      }
    );

    return this.client;
  }
}
