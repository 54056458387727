import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { apiClient } from '@/ApiClient';
import {
  getParamsForAgentMigrationDetails,
  getParamsForAgentMigrationPotential,
  getParamsForAgentMigrationSummary,
  getParamsForDistribution,
  getParamsForFilter,
  getParamsForHistory,
  getParamsForPerformance,
  getParamsForPerformanceDistribution,
  getParamsForTransactions,
  handlingDistribution,
  handlingFilter,
  handlingHistory,
  handlingPerformance,
  handlingPerformanceDistribution,
  handlingTransactions,
} from '@/components/ReportProfileButton/utils';
import MoreMenuItem from '@/components/ReportTable/MoreMenuItem';
import { objToQueryString } from '@/router/router';
import { AppDispatch, store } from '@/store';
import {
  EDistributionData,
  EFileTypes,
  EMeasures,
  EReportName,
} from '@/store/enums';
import { getFilterLists } from '@/store/filterListsSlice';
import { getKeySettings } from '@/store/keySettingsSlice';
import { RowWithButton } from '@/store/types';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Button, Menu, MenuItem } from '@mui/material';

import { filterQueryParams } from '../ReportTypesPanel/utils';

export const ReportProfileButton = (props: {
  row: RowWithButton | null;
  id: string;
  label: string | number | JSX.Element;
  fieldKey: string;
  allowFilter: boolean;
}) => {
  const { row, id, label, fieldKey, allowFilter } = props;
  const key = fieldKey;
  const { reportUnit, reportName } = useSelector(getKeySettings);
  const { labels } = useSelector(getFilterLists);
  const dispatch = useDispatch<AppDispatch>();
  const [anchorEl, setAnchorEl] = React.useState<any>(null);
  const [modifiedStartDate] = React.useState<string | null>('');
  const [modifiedEndDate] = React.useState<string | null>('');
  const { t } = useTranslation();

  const handleClick = (
    key: string,
    event:
      | React.MouseEvent<HTMLButtonElement>
      | React.TouchEvent<HTMLButtonElement>
  ) => {
    setAnchorEl({ [key]: event.currentTarget });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getHref = (params: Record<string, string>): string => {
    const urlParams = new URLSearchParams(
      filterQueryParams(window.location.search)
    );

    Object.entries(params).forEach(([key, value]) => {
      urlParams.set(key, value);

      if (value === '') {
        urlParams.delete(key);
      }

      urlParams.set('page', '1');
    });

    if (modifiedStartDate && modifiedEndDate) {
      urlParams.set('startDate', modifiedStartDate);
      urlParams.set('endDate', modifiedEndDate);
    }

    return `${window.location.origin}${
      window.location.pathname
    }?${urlParams.toString()}`;
  };

  const getHrefParams = (params: Record<string, string>): string => {
    const urlParams = new URLSearchParams(
      filterQueryParams(window.location.search)
    );

    Object.entries(params).forEach(([key, value]) => {
      urlParams.set(key, value);

      if (value === '') {
        urlParams.delete(key);
      }

      urlParams.set('page', '1');
    });

    if (modifiedStartDate && modifiedEndDate) {
      urlParams.set('startDate', modifiedStartDate);
      urlParams.set('endDate', modifiedEndDate);
    }

    return urlParams.toString();
  };

  const paramsFilter = getParamsForFilter(key, reportUnit, reportName, row);
  const paramsPerformance = getParamsForPerformance(key, reportUnit, row, id);
  const paramsDistribution = getParamsForDistribution(key, reportUnit, row, id);
  const paramsAgentMigrationSummary = getParamsForAgentMigrationSummary(
    key,
    reportUnit,
    row,
    id
  );
  const paramsAgentMigrationDetails = getParamsForAgentMigrationDetails(
    key,
    reportUnit,
    row,
    id
  );
  const paramsAgentMigrationPotential = getParamsForAgentMigrationPotential(
    key,
    reportUnit,
    row,
    id
  );
  const paramsTransactions = getParamsForTransactions(key, reportUnit, row, id);
  const paramsPerformanceDistribution = (data: string) =>
    getParamsForPerformanceDistribution(key, reportUnit, data, row, id);
  const paramsHistory = getParamsForHistory(key, row, id);

  const handleFilter = (e: any) => {
    e.preventDefault();

    handlingFilter(dispatch, key, reportUnit, reportName, row);
  };

  const handlePerformance = (e: any) => {
    e.preventDefault();

    handlingPerformance(dispatch, key, reportUnit, reportName, row, id);
  };

  const handlePerformanceDistribution = (e: any, data: string) => {
    e.preventDefault();

    handlingPerformanceDistribution(
      dispatch,
      key,
      reportUnit,
      reportName,
      data,
      row,
      id
    );
  };

  const handleDistribution = (e: any) => {
    e.preventDefault();

    handlingDistribution(dispatch, key, reportUnit, reportName, row, id);
  };

  const handleTransactions = (e: any) => {
    e.preventDefault();

    handlingTransactions(dispatch, key, reportUnit, reportName, row, id);
  };

  const handleHistory = (e: any) => {
    e.preventDefault();

    handlingHistory(dispatch, key, reportName, row, id);
  };

  const exportAgentMigrationSummaryReport = (e: any) => {
    e.preventDefault();
    toast.info(<Trans i18nKey="messages.few_moments" />);
    // eslint-disable-next-line
    const { mls, ...fields } = store.getState().keySettings;

    if (!mls) return;

    const params = getHrefParams(paramsAgentMigrationSummary);

    return apiClient
      .exportReport(
        mls,
        EReportName.AGENT_MIGRATION_SUMMARY,
        params,
        EFileTypes.TYPE_XLSX
      )
      .then((response) => {
        const url = window.URL || window.webkitURL;
        const filename = response.headers['content-disposition']
          .split('filename=')[1]
          .split('.')[0];
        const extension = response.headers['content-disposition']
          .split('.')[1]
          .split(';')[0];

        const link = url.createObjectURL(
          new Blob([response.data], {
            type: response.headers['content-type'],
          })
        );
        const a = document.createElement('a');
        a.download = `${filename}.${extension}`;
        a.href = link;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };

  const exportAgentMigrationDetailsReport = (e: any) => {
    e.preventDefault();
    toast.info(<Trans i18nKey="messages.few_moments" />);
    // eslint-disable-next-line
    const { mls, ...fields } = store.getState().keySettings;

    if (!mls) return;

    const params = getHrefParams(paramsAgentMigrationDetails);

    return apiClient
      .exportReport(
        mls,
        EReportName.AGENT_MIGRATION_DETAILS,
        params,
        EFileTypes.TYPE_XLSX
      )
      .then((response) => {
        const url = window.URL || window.webkitURL;
        const filename = response.headers['content-disposition']
          .split('filename=')[1]
          .split('.')[0];
        const extension = response.headers['content-disposition']
          .split('.')[1]
          .split(';')[0];

        const link = url.createObjectURL(
          new Blob([response.data], {
            type: response.headers['content-type'],
          })
        );
        const a = document.createElement('a');
        a.download = `${filename}.${extension}`;
        a.href = link;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };

  const exportAgentMigrationPotentialReport = (e: any) => {
    e.preventDefault();
    toast.info(<Trans i18nKey="messages.few_moments" />);
    // eslint-disable-next-line
    const { mls, ...fields } = store.getState().keySettings;

    if (!mls) return;

    const params = getHrefParams(paramsAgentMigrationPotential);

    return apiClient
      .exportReport(
        mls,
        EReportName.AGENT_MIGRATION_POTENTIAL,
        params,
        EFileTypes.TYPE_XLSX
      )
      .then((response) => {
        const url = window.URL || window.webkitURL;
        const filename = response.headers['content-disposition']
          .split('filename=')[1]
          .split('.')[0];
        const extension = response.headers['content-disposition']
          .split('.')[1]
          .split(';')[0];

        const link = url.createObjectURL(
          new Blob([response.data], {
            type: response.headers['content-type'],
          })
        );
        const a = document.createElement('a');
        a.download = `${filename}.${extension}`;
        a.href = link;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };

  const exportAgentProgressReport = (agentId: any, data: string) => {
    toast.info(<Trans i18nKey="messages.few_moments" />);
    // eslint-disable-next-line
    const { mls, ...fields } = store.getState().keySettings;

    if (!mls) return;

    const query =
      objToQueryString(fields) + `&agentIds=${agentId}` + `&data=${data}`;

    return apiClient
      .exportReport(mls, EReportName.AGENT_PROGRESS, query, EFileTypes.TYPE_PDF)
      .then((response) => {
        const url = window.URL || window.webkitURL;
        const filename = response.headers['content-disposition']
          .split('filename=')[1]
          .split('.')[0];
        const extension = response.headers['content-disposition']
          .split('.')[1]
          .split(';')[0];

        const link = url.createObjectURL(
          new Blob([response.data], {
            type: response.headers['content-type'],
          })
        );
        const a = document.createElement('a');
        a.download = `${filename}.${extension}`;
        a.href = link;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };

  const exportAgentDetailedSummaryReport = (
    agentId: any,
    measure: string,
    data: string
  ) => {
    toast.info(<Trans i18nKey="messages.few_moments" />);
    // eslint-disable-next-line
    const { mls, ...fields } = store.getState().keySettings;

    if (!mls) return;

    const query =
      objToQueryString(fields) +
      `&agentIds=${agentId}` +
      `&measure=${measure}` +
      `&data=${data}`;

    return apiClient
      .exportReport(
        mls,
        EReportName.AGENT_DETAILED_SUMMARY,
        query,
        EFileTypes.TYPE_PDF
      )
      .then((response) => {
        const url = window.URL || window.webkitURL;
        const filename = response.headers['content-disposition']
          .split('filename=')[1]
          .split('.')[0];
        const extension = response.headers['content-disposition']
          .split('.')[1]
          .split(';')[0];

        const link = url.createObjectURL(
          new Blob([response.data], {
            type: response.headers['content-type'],
          })
        );
        const a = document.createElement('a');
        a.download = `${filename}.${extension}`;
        a.href = link;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };

  return (
    <>
      <Button
        id="basic-button"
        aria-controls={anchorEl && anchorEl[key] ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={anchorEl && anchorEl[key] ? 'true' : undefined}
        className="menu-button"
        endIcon={<KeyboardArrowDownIcon className="menu-arrow" />}
        onClick={(e) => handleClick(key, e)}
        onTouchStart={(e) => handleClick(key, e)}
      >
        {label}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl && anchorEl[key]}
        open={anchorEl && anchorEl[key] ? Boolean(anchorEl[key]) : false}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {allowFilter && (
          <a
            href={getHref(paramsFilter)}
            role="button"
            target="_blank"
            rel="noreferrer"
            style={{ color: 'var(--reports-links)', fontSize: '12px' }}
            onClick={handleFilter}
            onTouchStart={handleFilter}
          >
            <MenuItem style={{ fontSize: 'inherit', fontWeight: 'bold' }}>
              {t(`buttons.${key}_filter`)}
            </MenuItem>
          </a>
        )}
        <a
          href={getHref(paramsPerformance)}
          role="button"
          target="_blank"
          rel="noreferrer"
          onClick={handlePerformance}
          onTouchStart={handlePerformance}
          style={{ color: 'var(--reports-links)', fontSize: '12px' }}
        >
          <MenuItem style={{ fontSize: 'inherit' }}>
            {t(`buttons.${key}_performance_graphs`)}
          </MenuItem>
        </a>
        <MoreMenuItem label={t(`buttons.${key}_performance_distribution`)}>
          {Object.values(EDistributionData).map((distributionData) => {
            const labelFromLabels = labels.find(
              (item) => item.type === distributionData
            )?.name;

            return (
              <a
                key={distributionData}
                role="button"
                href={getHref(paramsPerformanceDistribution(distributionData))}
                target="_blank"
                rel="noreferrer"
                onClick={(e) => {
                  handlePerformanceDistribution(e, distributionData);
                }}
                onTouchStart={(e) => {
                  handlePerformanceDistribution(e, distributionData);
                }}
                style={{
                  color: 'var(--reports-links)',
                  fontSize: '12px',
                }}
              >
                <MenuItem
                  onClick={handleClose}
                  onTouchStart={handleClose}
                  style={{ fontSize: 'inherit' }}
                >
                  {labelFromLabels
                    ? labelFromLabels
                    : t(`buttons.${distributionData}`)}
                </MenuItem>
              </a>
            );
          })}
        </MoreMenuItem>
        {key === 'agentName'
          ? [
              <MoreMenuItem
                key={t(`reports.agent-progress`)}
                label={t(`reports.agent-progress`)}
              >
                {Object.values(EDistributionData).map((distributionData) => {
                  const labelFromLabels = labels.find(
                    (item) => item.type === distributionData
                  )?.name;

                  return (
                    <a
                      key={distributionData}
                      target="_blank"
                      rel="noreferrer"
                      onClick={() =>
                        exportAgentProgressReport(
                          row ? row.id : id,
                          distributionData
                        )
                      }
                      onTouchStart={() =>
                        exportAgentProgressReport(
                          row ? row.id : id,
                          distributionData
                        )
                      }
                      style={{
                        color: 'var(--reports-links)',
                        fontSize: '12px',
                        textDecoration: 'underline',
                      }}
                    >
                      <MenuItem
                        onClick={handleClose}
                        onTouchStart={handleClose}
                        style={{ fontSize: 'inherit' }}
                      >
                        {labelFromLabels
                          ? labelFromLabels
                          : t(`buttons.${distributionData}`)}
                      </MenuItem>
                    </a>
                  );
                })}
              </MoreMenuItem>,
              <MoreMenuItem
                key={t(`reports.agent-detailed-summary`)}
                label={t(`reports.agent-detailed-summary`)}
              >
                {Object.values(EMeasures).map((measure) => {
                  return (
                    <MoreMenuItem key={measure} label={t(`buttons.${measure}`)}>
                      {Object.values(EDistributionData).map(
                        (distributionData) => {
                          const labelFromLabels = labels.find(
                            (item) => item.type === distributionData
                          )?.name;

                          return (
                            <a
                              key={distributionData}
                              target="_blank"
                              rel="noreferrer"
                              onClick={() =>
                                exportAgentDetailedSummaryReport(
                                  row ? row.id : id,
                                  measure,
                                  distributionData
                                )
                              }
                              onTouchStart={() =>
                                exportAgentDetailedSummaryReport(
                                  row ? row.id : id,
                                  measure,
                                  distributionData
                                )
                              }
                              style={{
                                color: 'var(--reports-links)',
                                fontSize: '12px',
                                textDecoration: 'underline',
                              }}
                            >
                              <MenuItem
                                onClick={handleClose}
                                onTouchStart={handleClose}
                                style={{ fontSize: 'inherit' }}
                              >
                                {labelFromLabels
                                  ? labelFromLabels
                                  : t(`buttons.${distributionData}`)}
                              </MenuItem>
                            </a>
                          );
                        }
                      )}
                    </MoreMenuItem>
                  );
                })}
              </MoreMenuItem>,
            ]
          : [
              <a
                key={t(`buttons.${key}_agent_distribution`)}
                href={getHref(paramsDistribution)}
                role="button"
                target="_blank"
                rel="noreferrer"
                onClick={handleDistribution}
                onTouchStart={handleDistribution}
                style={{ color: 'var(--reports-links)', fontSize: '12px' }}
              >
                <MenuItem style={{ fontSize: 'inherit' }}>
                  {t(`buttons.${key}_agent_distribution`)}
                </MenuItem>
              </a>,
              <a
                key={t(`buttons.${key}_agent_migration_summary`)}
                role="button"
                target="_blank"
                rel="noreferrer"
                onClick={exportAgentMigrationSummaryReport}
                onTouchStart={exportAgentMigrationSummaryReport}
                style={{ color: 'var(--reports-links)', fontSize: '12px' }}
              >
                <MenuItem style={{ fontSize: 'inherit' }}>
                  {t(`buttons.${key}_agent_migration_summary`)}
                </MenuItem>
              </a>,
              <a
                key={t(`buttons.${key}_agent_migration_details`)}
                role="button"
                target="_blank"
                rel="noreferrer"
                onClick={exportAgentMigrationDetailsReport}
                onTouchStart={exportAgentMigrationDetailsReport}
                style={{ color: 'var(--reports-links)', fontSize: '12px' }}
              >
                <MenuItem style={{ fontSize: 'inherit' }}>
                  {t(`buttons.${key}_agent_migration_details`)}
                </MenuItem>
              </a>,
              <a
                key={t(`buttons.${key}_agent_migration_potential`)}
                role="button"
                target="_blank"
                rel="noreferrer"
                onClick={exportAgentMigrationPotentialReport}
                onTouchStart={exportAgentMigrationPotentialReport}
                style={{ color: 'var(--reports-links)', fontSize: '12px' }}
              >
                <MenuItem style={{ fontSize: 'inherit' }}>
                  {t(`buttons.${key}_agent_migration_potential`)}
                </MenuItem>
              </a>,
            ]}
        <a
          href={getHref(paramsTransactions)}
          role="button"
          target="_blank"
          rel="noreferrer"
          onClick={handleTransactions}
          onTouchStart={handleTransactions}
          style={{ color: 'var(--reports-links)', fontSize: '12px' }}
        >
          <MenuItem style={{ fontSize: 'inherit' }}>
            {t(`buttons.${key}_transactions`)}
          </MenuItem>
        </a>
        {key === 'agentName' && (
          <a
            href={getHref(paramsHistory)}
            role="button"
            target="_blank"
            rel="noreferrer"
            onClick={handleHistory}
            onTouchStart={handleHistory}
            style={{ color: 'var(--reports-links)', fontSize: '12px' }}
          >
            <MenuItem style={{ fontSize: 'inherit' }}>
              {t(`reports.agent-history`)}
            </MenuItem>
          </a>
        )}
      </Menu>
    </>
  );
};
